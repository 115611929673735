import { Spinner } from "@nextui-org/spinner";

function SuspenseContent() {
  return (
    <div className="w-full h-screen flex items-center poppins-bold justify-center text-gray-600 dark:text-gray-500 bg-base-100">
      Loading...
    </div>
  );
}

export function LoaderSuspense() {
  return (
    <div className="w-full h-full inter-medium flex items-center justify-center text-gray-300 dark:text-gray-200 bg-base-100">
      <Spinner label="Loading..." />
    </div>
  );
}


export default SuspenseContent;
